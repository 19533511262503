import { Link } from "gatsby"
import styled from 'styled-components'
import React from "react"
import theme from '../theme'
import logo from '../images/logo.svg'
import useScrollBreakpoint from '../hooks/useScrollBreakpoint'
import { BsEnvelope } from 'react-icons/bs'
import { BiPhoneCall } from 'react-icons/bi'

const StyledHeader = styled.header`
  display: none;
  position: sticky;
  top: 0;
  left: 0;
  background: white;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  transition: height .2s ease-in;
  align-items: center;
  height: 68px;
  z-index: 100;
  &.attached {
    height: 88px;
  }
  nav {
    margin: auto;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  @media(min-width: 1024px) {
    display: flex;
  }
`

const StyledLogoContainer = styled.div`
  transition: width .2s ease-in; 
  margin-right: auto;  
  width: 150px;
  &.attached {
    width: 200px;
  }
`
const StyledUl = styled.ul`

  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  margin: 0;
  li {
    padding: 0 10px;
  }
  a {
    color: rgba(0,0,0,0.74);;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    transition: opacity .2s linear;
    &:hover {
      opacity: .8 
    }
    &[aria-current="page"] {
      color: ${theme.primaryColor}
    }
  }

`

const Header = () => {

  const broken = useScrollBreakpoint(30, false)

  return (
    <StyledHeader className={broken ? "" : "attached"}>
      <nav className="container">
        <StyledLogoContainer className={broken ? "" : "attached"}>
          <Link to='/'>
            <img src={logo} />
          </Link>
        </StyledLogoContainer>
        <StyledUl>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/book-appointment">Book Appointment</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li><BsEnvelope fontSize='20px' style={{position: "relative", top: "4px"}} color={theme.primaryColor}/> <a href="mailto:anl@xtra.co.nz">anl@xtra.co.nz</a></li>
          <li><BiPhoneCall fontSize='20px' style={{position: "relative", top: "4px"}} color={theme.primaryColor}/><a href="tel:098277563">(09) 827 7563</a></li>
        </StyledUl>
      </nav>
    </StyledHeader>
  )
}

export default Header
