import React from 'react'
import Footer from './footer'
import Header from './header'
import MobileHeader from './MobileHeader'
import { Layout } from "../styles"
import BottomCta from './BottomCta'

const layout = ({ children }) => {
  return (
    <Layout>
      <MobileHeader />
      <Header />
      {children}
      <BottomCta />
      <Footer />
    </Layout>
  )
}

export default layout
