import styled from "styled-components"
import theme from "./theme"

export const Layout = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 180px;
  box-sizing: border-box;

  .primary-underline {
    position: relative;
    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 100px;
      height: 3px;
      background: ${theme.primaryColor};
    }
    &:after {
      bottom: -5px;
      left: 8px;
    }
    &:before {
      bottom: 3px;
      left: 10px;
    }
  }

  .container {
    max-width: ${theme.maxWidthMd};
    margin: auto;
  }

  .mt-md {
    margin-top: 40px;
  }

  .content-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 1370px) {
    .container {
      max-width: ${theme.maxWidthXl};
    }
  }
`
