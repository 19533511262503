import React, { useState } from 'react'
import { Collapse } from 'react-collapse'
import { GiHamburgerMenu } from 'react-icons/gi'
import logo from '../images/logo.svg'
import styled from 'styled-components'
import { Link } from 'gatsby'
import theme from '../theme'

const MobileHeader = () => {

  const [isOpened, setIsOpened] = useState(false)

  return (

    <StyledHeader>
      <nav>

        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="ANL Logo" />
          </Link>
        </div>

        {/* Hamburger menu toggle */}
        <GiHamburgerMenu fontSize="23px" onClick={() => setIsOpened(!isOpened)} color={theme.primaryColor}/>

        <StyledMenu isOpened={isOpened}>
          <Collapse isOpened={isOpened}>
            <ul>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/book-appointment">Book appointment</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <a href="mailto:anl@xtra.co.nz" target='blank'>anl@xtra.co.nz</a>
              </li>
              <li>
                <a href="tel:098277563" target='blank'>(09) 827 7563</a>
              </li>
            </ul>
          </Collapse>
        </StyledMenu>
      </nav>
    </StyledHeader>

  )
}

const StyledMenu = styled.div`

  position: absolute;
  width: 100%;
  bottom: 0;
  transform: translateY(100%);
  z-index: 100;
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
  .ReactCollapse--content {
    border-top: 3px solid ${theme.primaryColor};
    box-shadow: 0 2px 5px rgba(0,0,0,.1);
    background: white;
    padding: 20px ;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      a {
        color: black;
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
      }
      li {
        padding: 10px 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03)
      }
    }
  }
`


const StyledHeader = styled.header`
  width: 100%;
  height: 80px;

  .logo-container {
    width: 150px;
  }

  nav {
    height: 100%;
    position: relative;
    max-width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media(min-width: 1024px) {
    display: none;
  }

`

export default MobileHeader
