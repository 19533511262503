import React from 'react'
import styled from 'styled-components'
import theme from '../theme'
import logo from '../images/logo.svg'
import { Link } from 'gatsby'
import { BsEnvelope } from 'react-icons/bs'
import { BiPhoneCall } from 'react-icons/bi'
import { FaFacebookF } from 'react-icons/fa'


const footer = () => {
  return (
    <StyledFooter>
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="ANL Logo" />
          </Link>
        </div>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li><BsEnvelope fontSize='20px' style={{ position: "relative", top: "4px" }} color={theme.primaryColor} /> <a href="mailto:anl@xtra.co.nz">anl@xtra.co.nz</a></li>
          <li><BiPhoneCall fontSize='20px' style={{ position: "relative", top: "4px" }} color={theme.primaryColor} /><a href="tel:098277563">(09) 827 7563</a></li>
        </ul>
      </nav>
      <div className='bottomFooter'>
        <span>Copyright © {(new Date()).getFullYear()} ANL - Automotive New Lynn</span>
        <a href="https://www.facebook.com/AutomotiveNewLynn/">
          <FaFacebookF fontSize="20px" color={theme.graySmooth}/>
        </a>
      </div>
    </StyledFooter>
  )
}


const StyledFooter = styled.footer`
  
  position: absolute;
  background: ${theme.gray};
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0 15px;
  box-sizing: border-box;

  span {
    font-size: 14px;
    color: ${theme.graySmooth};
  }
  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    width: 450px;
    a {
      font-size: 14px;
      color: ${theme.graySmooth};
      text-decoration: none;
    } 
  }
  nav {
    max-width: ${theme.maxWidthMd};
    margin: auto;
    height: 80px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    margin-right: auto;
    width: 150px;
  } 
  .bottomFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    max-width: ${theme.maxWidthMd};
    margin: auto;
  }

  @media(max-width: 768px) {
    nav {
      display: block;
      padding-bottom: 30px;
      height: 150px;
    }
    .logo {
      margin: auto;
    }
    ul {
      margin: auto;
      flex-direction: column;
      width: auto;
    }
    li {
      text-align: center;
    }

  }
`

export default footer
