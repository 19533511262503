import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import theme from '../theme'


const Button = (props) => {

  let stylesForType = noBg;
  switch (props.type) {
    case "greenToOutline":
      stylesForType = greenToOutline
      break;
    case "greenToNone":
      stylesForType = greenToNone    
      break;
    case "greenToBlack":
      stylesForType = greenToBlack    
      break;
    case "noneToGreen":
      stylesForType = noneToGreen 
      break;
    case "whiteNoneToGreen":
      stylesForType = whiteNoneToGreen
  }

  return (
    <StyledButton {...props} stylesForType={stylesForType} to={props.to}>{props.children}</StyledButton>
  )
}

const noBg = css`
  border: 2px solid white;
  color: white;
`

const greenToNone = css`
  border: 2px solid ${theme.primaryColor};
  background: ${theme.primaryColor};
  color: white;
  &:hover {
    color: ${theme.primaryColor};
    background: transparent;
    border-color: transparent;
  } 
`

const noneToGreen = css`
  border: 2px solid ${theme.primaryColor};
  color: ${theme.primaryColor};
  &:hover {
    background: ${theme.primaryColor};
    color: white;
  }
`

const whiteNoneToGreen = css`
  border: 2px solid white;
  color: white;
  &:hover {
    background: ${theme.primaryColor};
    border: 2px solid ${theme.primaryColor}
  }
`
const greenToBlack = css`
  border: 2px solid ${theme.primaryColor} ;
  background: ${theme.primaryColor};
  color: white;
  &:hover {
    background: black;
    border: 2px solid black;
  }
`
const greenToOutline = css`
  border: 2px solid ${theme.primaryColor};
  background: ${theme.primaryColor};
  color: white;
  &:hover {
    border: 2px solid ${theme.primaryColor} !important;
    background: white;
    color: ${theme.primaryColor}
  }
`

  const StyledButton = styled(Link)`
    ${props => props.stylesForType};
    text-decoration: none;
    display: inline-block;
    letter-spacing: 3px;
    transform: skewX(-22deg) skewY(0deg); 
    text-transform: uppercase;
    transition: all .2s linear;
    font-weight: bold;
    font-size: .875rem;
    padding: 15px 40px;
    z-index: 90;
    &:hover {
      border-color: transparent;
    }
    &::after {
      content: "E";
      font-family: "elegant-icons";
      font-size: 14px;
      position: relative;
      bottom: -2px;
      right: -4px;
    }
  `

Button.propTypes = {
  type: PropTypes.oneOf(["noBg", "greenToBlack", "greenToOutline", "greenToNone", "noneToGreen", "whiteNoneToGreen"])
}
Button.defaultProps = {
  type: "noBg",
}

export default Button
