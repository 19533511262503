import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { FaPhoneAlt } from 'react-icons/fa'
import Button from './Button'
import styled from 'styled-components'
import theme from '../theme'

const BottomCta = () => {

  const { text, shapes, hands, hands1, car } = useStaticQuery(graphql`
  {
    text: contentfulBottomCta {
      mitsubishiCertifiedDescription {
        mitsubishiCertifiedDescription
      }
      bookAppointmentDescription {
        bookAppointmentDescription
      }
    }    

    shapes: file(name: {eq: "mechanic-34"}) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }   
      }
    }

    hands: file(name: {eq: "mechanic-87"}) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }   
      }
    }

    hands1: file(name: {eq: "mechanic-90"}) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }   
      }
    }

    car: file(name: {eq: "front-left-2080-en_US"}) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }   
      }
    }
  }
  `)


  return (
    <StyledBackground fluid={shapes.childImageSharp.fluid}>
      <StyledSection>
        <StyledBackgroundSection fluid={["linear-gradient(180deg,#40a629 0%,rgba(64,166,41,0.24) 100%)", car.childImageSharp.fluid]}>
          <h2>Mitsubishi certified</h2>
          <p>{text.mitsubishiCertifiedDescription.mitsubishiCertifiedDescription}</p>
        </StyledBackgroundSection>
        <StyledBackgroundSection fluid={["linear-gradient(180deg,rgba(2,24,39,0.7) 0%,rgba(2,24,39,0.6) 100%)", hands.childImageSharp.fluid]}>
          <h2>Book appointment</h2>
          <p>{text.bookAppointmentDescription.bookAppointmentDescription}</p>
          <section className="btn-wrapper">
            <Button to='/book-appointment'>Book online</Button>
          </section>
        </StyledBackgroundSection>
        <StyledBackgroundSection fluid={["linear-gradient(180deg,rgba(255,255,255,0.9) 0%,#ffffff 100%)", hands1.childImageSharp.fluid]}>
          <h2>Contact us</h2>
          <p id="call-us"><FaPhoneAlt style={{marginRight: "10px"}}/> Call us</p>
          <a id="phone-number-link" href="tel:098277563">09 827 7563</a>
          <section className="btn-wrapper">
            <Button to="/contact" type="greenToBlack">Message us</Button>
          </section>
        </StyledBackgroundSection>
      </StyledSection>
    </StyledBackground>
  )
}

const StyledBackgroundSection = styled(BackgroundImage)`



`

const StyledSection = styled.section`

  max-width: 1360px;
  margin: auto;
  display: flex;
  color: white;

  #call-us {
    font-family: "Khand";
    text-transform: uppercase;
  }
  #phone-number-link {
    color: ${theme.primaryColor};
    text-decoration: none;
    padding-left: 28px;
    font-family: "Khand";
    font-weight: bold;
    font-size: 28px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px
  }

  div {
    flex: 30%;
    padding: 100px 40px;
    &:nth-of-type(3) {
      color: #333;
    }
  }
  h2 {
    text-transform: uppercase;
    font-family: "Khand";
    font-size: 35px;
    margin-top: 0;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    line-height: 2em;
  }

  @media(max-width: 900px) {
    display: block;
  }

`

const StyledBackground = styled(BackgroundImage)`

  background-color: #eee;
  background-position: "bottom";
  padding: 8vh 0;


`

export default BottomCta
