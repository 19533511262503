import {useEffect, useState } from 'react'

const useScrollBreakpoint = (breakpoint: number, defaultValue: boolean = false) => {
  const [ broken, setBroken ] = useState<boolean>(defaultValue);
  
  const updateBroken = () => {
    setBroken(window.scrollY > breakpoint)
  }

  useEffect(() => {
    window.addEventListener('scroll', updateBroken)
    return () => {
      window.removeEventListener('scroll', updateBroken)
    }
  }, []);

  return broken
}

export default useScrollBreakpoint